var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderableApps && _vm.currentApp
    ? _c("div", { staticClass: "menu menu--show" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.developerUser
          ? _c(
              "div",
              { staticClass: "menu-items" },
              _vm._l(_vm.renderableApps, function(app, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "menu-item-wrap",
                    class: { active: app.name === _vm.currentApp.name },
                    attrs: { index: i.toString() },
                    on: {
                      click: function($event) {
                        return _vm.chooseApp(app)
                      }
                    }
                  },
                  [
                    app.name === "schedule"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader:
                              app.name === _vm.currentApp.name ||
                              _vm.currentApp.name === "home"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "menu-items" },
              _vm._l(_vm.renderableApps, function(app, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "menu-item-wrap",
                    class: { active: app.name === _vm.currentApp.name },
                    attrs: { index: i.toString() },
                    on: {
                      click: function($event) {
                        return _vm.chooseApp(app)
                      }
                    }
                  },
                  [
                    app.name === "publish"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader:
                              app.name === _vm.currentApp.name ||
                              "home" === _vm.currentApp.name
                          }
                        })
                      : app.name === "content-uploader"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader: app.name === _vm.currentApp.name
                          }
                        })
                      : app.name === "content"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader: app.name === _vm.currentApp.name
                          }
                        })
                      : app.name === "human-handoff"
                      ? _c(
                          "menu-item",
                          {
                            attrs: {
                              title: app.title,
                              activeHeader: app.name === _vm.currentApp.name
                            }
                          },
                          [_c("HumanHandoffIcon")],
                          1
                        )
                      : app.name === "qna"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader: app.name === _vm.currentApp.name
                          }
                        })
                      : app.name === "response-manager"
                      ? _c("menu-item", {
                          attrs: {
                            iconSrc: app.icon,
                            title: app.title,
                            activeHeader: app.name === _vm.currentApp.name
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-items" }, [
          _vm.settingsApp
            ? _c(
                "div",
                {
                  staticClass: "settings menu-item-wrap",
                  class: {
                    active: _vm.settingsApp.name === _vm.currentApp.name
                  },
                  on: {
                    click: function($event) {
                      return _vm.chooseApp(_vm.settingsApp)
                    }
                  }
                },
                [
                  _c("menu-item", {
                    attrs: {
                      iconSrc: _vm.settingsApp.icon,
                      title: _vm.settingsApp.title,
                      activeHeader: _vm.settingsApp.name === _vm.currentApp.name
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "logout menu-item-wrap", on: { click: _vm.logout } },
            [_c("div", { staticClass: "menu-item" }, [_c("LogoutIcon")], 1)]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-logo" }, [
      _c("img", {
        attrs: { src: "http://d1hud7do04ixcl.cloudfront.net/logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }