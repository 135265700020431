<template>
  <div class="menu menu--show" v-if="renderableApps && currentApp">
    <div class="menu-logo">
      <img src="http://d1hud7do04ixcl.cloudfront.net/logo.png" />
    </div>
    <div class="menu-items" v-if="developerUser">
      <div
        class="menu-item-wrap"
        v-for="(app, i) in renderableApps"
        v-bind:key="i"
        @click="chooseApp(app)"
        :index="i.toString()"
        :class="{ active: app.name === currentApp.name }"
      >
        <menu-item
          v-if="app.name === 'schedule'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="
            app.name === currentApp.name || currentApp.name === 'home'
          "
        />
      </div>
    </div>
    <div v-else class="menu-items">
      <div
        class="menu-item-wrap"
        v-for="(app, i) in renderableApps"
        v-bind:key="i"
        @click="chooseApp(app)"
        :index="i.toString()"
        :class="{ active: app.name === currentApp.name }"
      >
        <menu-item
          v-if="app.name === 'publish'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="
            app.name === currentApp.name || 'home' === currentApp.name
          "
        />
        <menu-item
          v-else-if="app.name === 'content-uploader'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="app.name === currentApp.name"
        />
        <menu-item
          v-else-if="app.name === 'content'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="app.name === currentApp.name"
        />
        <menu-item
          v-else-if="app.name === 'human-handoff'"
          :title="app.title"
          :activeHeader="app.name === currentApp.name"
        >
          <HumanHandoffIcon />
        </menu-item>
        <menu-item
          v-else-if="app.name === 'qna'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="app.name === currentApp.name"
        />
        <menu-item
          v-else-if="app.name === 'response-manager'"
          :iconSrc="app.icon"
          :title="app.title"
          :activeHeader="app.name === currentApp.name"
        />
      </div>
    </div>
    <div class="bottom-items">
      <div
        class="settings menu-item-wrap"
        v-if="settingsApp"
        @click="chooseApp(settingsApp)"
        :class="{ active: settingsApp.name === currentApp.name }"
      >
        <menu-item
          :iconSrc="settingsApp.icon"
          :title="settingsApp.title"
          :activeHeader="settingsApp.name === currentApp.name"
        />
      </div>
      <div class="logout menu-item-wrap" @click="logout">
        <div class="menu-item">
          <LogoutIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Broadcast from '../../dashboard/broadcast/broadcast.vue';
import ContentPage from '../../dashboard/content/content.vue';
import HumanHandoff from '../../dashboard/human-handoff/human-handoff.vue';
import MenuItem from './menu-item/menu-item.vue';
import QnA from '../../dashboard/qna/qna.vue';
import ResponseManager from '../../dashboard/response-manager/response-manager.vue';
import Schedule from '../../developer/schedule/schedule.vue';
import Settings from '../../components/settings/settings.vue';
import Analytics from '../../dashboard/analytics.vue';
import '../../assets/icon-side-nav-logo.svg';
import '../../assets/icon-side-nav-home.svg';
import '../../assets/icon-side-nav-broadcast.svg';
import '../../assets/icon-side-nav-scheduler.svg';
import '../../assets/icon-side-nav-manager.svg';
import '../../assets/icon-side-nav-analytics.svg';
import '../../assets/icon-side-nav-settings.svg';
import '../../assets/icon-side-nav-uploader.svg';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

import LogoutIcon from '../../assets/svg-components/logout-nav-icon.svg';
import HumanHandoffIcon from '../../assets/svg-components/human-handoff.svg';

const developerApps = [
  {
    name: 'home',
    title: 'Home',
    route: '/',
    component: Schedule,
    icon: 'home',
  },
  {
    name: 'schedule',
    title: 'Schedule',
    route: '/app/schedule',
    component: Schedule,
    icon: 'send',
  },
  {
    name: 'settings',
    title: 'Settings',
    route: '/app/settings',
    component: Settings,
    icon: 'settings',
  },
];

const dashboardApps = [
  {
    name: 'home',
    title: 'Home',
    route: '/',
    component: Broadcast,
    icon: 'home',
  },
  {
    name: 'publish',
    title: 'Broadcast',
    route: '/app/broadcast',
    component: Broadcast,
    icon: 'tap_and_play',
  },
  {
    name: 'content',
    title: 'Content',
    route: '/app/content',
    component: ContentPage,
    icon: 'mms',
  },
  {
    name: 'human-handoff',
    title: 'Human Handoff',
    route: '/app/human-handoff',
    component: HumanHandoff,
    icon: '',
  },
  {
    name: 'analytics',
    title: 'Analytics',
    route: '/app/analytics',
    component: Analytics,
    icon: '/dist/assets/icon-side-nav-analytics.svg',
  },
  {
    name: 'settings',
    title: 'Settings',
    route: '/app/settings',
    component: Settings,
    icon: 'settings',
  },
  {
    name: 'qna',
    title: 'QnA',
    route: '/app/qna',
    component: QnA,
    icon: 'contact_support',
  },
  {
    name: 'response-manager',
    title: 'Response Manager',
    route: '/app/response-manager',
    component: ResponseManager,
    icon: 'question_answer',
  },
];

export default {
  components: {
    LogoutIcon,
    MenuItem,
    HumanHandoffIcon,
  },
  props: {
    navType: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters('user', ['apps']),
    ...mapState(['currentApp']),
    ...mapState('user', ['data']),
    developerUser() {
      return 'developer' === this.navType;
    },
    renderableApps() {
      const apps = this.developerUser ? developerApps : dashboardApps;
      if (this.apps) {
        return apps.filter(
          (app) =>
            Object.keys(this.apps).includes(app.name) && 'settings' !== app.name
        );
      }
      return [];
    },
    settingsApp() {
      try {
        const apps = this.developerUser ? developerApps : dashboardApps;
        return apps.filter((app) => 'settings' === app.name)[0];
      } catch (e) {
        return null;
      }
    },
    route() {
      return window.location.pathname;
    },
  },
  methods: {
    ...mapMutations(['setApp']),
    ...mapActions('user', ['logout']),
    chooseApp(app) {
      this.setApp(app);
    },
  },
  watch: {
    renderableApps: {
      handler() {
        const allowedPage = this.renderableApps.find(
          (a) => a.route === this.route
        );
        const settingsPage = this.settingsApp.route === this.route;

        if (allowedPage) {
          this.setApp(allowedPage);
        } else if (settingsPage) {
          this.setApp(this.settingsApp);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'gameon-components/src/universal/vars';
@import './main-nav.scss';
</style>
